<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          :serviceType="2"
        ></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import storedValueModule from '@/store/modules/storedValue'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    IndexTable: () => import('@/components/storedvalue/bottom/Index'),
    storedSummary: () => import('@/components/storedvalue/bottom/Summary')
  },
  created () {
    if (!this.$store.hasModule('storedValue')) {
      this.$store.registerModule('storedValue', storedValueModule)
    }
  },

  mounted () {
    this.setRightComponent('storedSummary')
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('IndexTable')
  },
  destroyed () {
    // this.$store.unregisterModule('storedValue')
  }
}
</script>
