
const storedValue = {
  state: {
    pageType: '',
    secondPartyArray: [],
    secondPartyId: 0
  },
  mutations: {
    set_storedValue_pageType (state, data) {
      state.pageType = data
    },
    set_storedValue_secondPartyArray (state, data) {
      state.secondPartyArray = data
    },
    set_storedValue_secondPartyId (state, data) {
      state.secondPartyId = data
    }
  }
}

export default storedValue
